import request from "@/utils/request";

let baseUrl = "";
if (window.location.hostname.indexOf("dev-") !== -1) {
  baseUrl = "https://test-crm.cnabke.com/";
} else if (
  window.location.hostname.indexOf("test") !== -1 ||
  window.location.hostname.indexOf("localhost") !== -1
) {
  baseUrl = "https://test-crm.cnabke.com/";
} else {
  baseUrl = "https://crm.cnabke.com/";
}
/*####################搜邮寻客############################*/
/**
 * 全网搜邮
 * @param {*} params
 * @returns
 */
export function keywordSearch(data) {
  return request({
    url: "/app-crm/productService/keywordSearch",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 全网搜邮
 * @param {*} params
 * @returns
 */
export function deepSearch(data) {
  return request({
    url: "/app-crm/productService/deepSearch",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/*####################END############################*/

/*####################海关数据############################*/
/**
 * 查询海关订单
 * @param {*} params
 * @returns
 */
export function customsWebSearch(data) {
  return request({
    url: "/app-crm/customer/customs/webSearch",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/*####################END############################*/

/*####################客户智搜############################*/
/**
 * 客户智搜搜索
 * @param {*} params
 * @returns
 */
export function enterpriseWebSearch(data) {
  return request({
    url: "/app-crm/enterprise/webSearch",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/*####################END############################*/

/*####################资源导航############################*/
/**
 * 资源类型
 * @param {*} params
 * @returns
 */
export function resourcesType(data) {
  return request({
    url: "/app-crm/productService/resourcesType",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 资源列表
 * @param {*} params
 * @returns
 */
export function resourcesList(data) {
  return request({
    url: "/app-crm/productService/resourcesList",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 常见问题列表
 * @param {*} params
 * @returns
 */
export function listProblem(data) {
  return request({
    url: "/app-crm/information/problem/listProblem",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}
/*####################END############################*/
/*####################外贸学院############################*/
/**
 * 资讯列表
 * @param {*} params
 * @returns
 */
export function listContent(data) {
  return request({
    url: "/app-crm/information/content/listContent",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 资讯详情
 * @param {*} params
 * @returns
 */
export function getContent(data) {
  return request({
    url: "/app-crm/information/content/getContent",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 标签列表
 * @param {*} params
 * @returns
 */
export function getTags(data) {
  return request({
    url: "/app-crm/information/content/getTags",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 课程分类
 * @param {*} params
 * @returns
 */
export function getListCourseClassify(data) {
  return request({
    url: "/app-crm/information/course/listCourseClassifyAll",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 资讯课程列表
 * @param {*} params
 * @returns
 */
export function getListCourse(data) {
  return request({
    url: "/app-cms/information/course/listCourse",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 资讯课程详情
 * @param {*} params
 * @returns
 */
export function getCourse(data) {
  return request({
    url: "/app-cms/information/course/getCourse",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 观看计数
 * @param {*} params
 * @returns
 */
export function viewCourseCount(data) {
  return request({
    url: "/app-cms/information/course/update",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}
/*####################END############################*/

/*####################模板############################*/
/**
 * 查询条件初始化接口
 * @param {*} params
 * @returns
 */
export function getInitialQueryList(data) {
  return request({
    url: "/app-cms/webpage/template/initialQueryList",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 查询条件接口
 * @param {*} params
 * @returns
 */
export function getTemplateQueryList(data) {
  return request({
    url: "/app-cms/webpage/template/templateQueryList",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}
/*####################END############################*/

/*####################问题中心############################*/
/**
 * 问题中心首页 新手入门、热门问题
 * @param {*} params
 * @returns
 */
export function getListInformationArticle(data) {
  return request({
    url: "/app-cms/information/helpCenter/article/listInformationArticle",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 问题中心首页 新手入门、热门问题
 * @param {*} params
 * @returns
 */
export function getListInformationTitle(data) {
  return request({
    url: "app-cms/information/helpCenter/category/listInformationTitle",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 常见问题内容接口
 * @param {*} params
 * @returns
 */
export function getArcitleById(data) {
  return request({
    url: "app-cms/information/helpCenter/article/getArcitleById",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 手册内容接口
 * @param {*} params
 * @returns
 */
export function getArcitleByType(data) {
  return request({
    url: "app-cms/information/helpCenter/article/getArcitleByType",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

/**
 * 内容中心搜索接口
 * @param {*} params
 * @returns
 */
export function getQueryByKeyWords(data) {
  return request({
    url: "app-cms/information/helpCenter/article/queryByKeyWords",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function getMenuBySeriesId(data) {
  return request({
    url: "app-cms/information/course/series/getMenu",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}
// 新闻增加阅读量
export function addRead(data) {
  return request({
    url: "/app-cms/information/content/addRead",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function getLoginUser(data) {
  return request({
    url: baseUrl + "admin/login/getLoginUser.do",
    method: "post",
    headers: {
      token: data.token,
    },
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function recommendCourse(data) {
  return request({
    url: "/app-cms/information/course/recommend",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function subscribeCourse(data) {
  return request({
    url: "/app-cms/information/course/subscribe",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function chapterList(data) {
  return request({
    url: "/app-cms/information/course/chapter/chapterList",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function listBySeriesId(data) {
  return request({
    url: "/app-cms/information/course/chapter/listBySeriesId",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

export function allChapterList(data) {
  return request({
    url: "/app-cms/information/course/chapter/allList",
    method: "post",
    data, // post 的时候用 data，get 的时候 用 params
  });
}

// 获取验证码
export function getLoginCode(data) {
  return request({
    url: baseUrl + "admin/login/getLoginCode.do",
    method: "post",
    data,
  });
}
// 验证码登录
export function doLoginByCode(data) {
  return request({
    url: baseUrl + "admin/login/doLoginByCode.do",
    method: "post",
    data,
  });
}
/*####################END############################*/
