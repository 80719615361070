import axios from 'axios';
import { message } from 'ant-design-vue';
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// const Message = message()
// const Msgbox = useMsgbox()
// create an axios instance

let baseUrl = ''
if (window.location.hostname.indexOf('dev-') !== -1) {
  // baseUrl = 'https://dev-plat.cnabke.com'
  baseUrl = 'https://test-plat.cnabke.com/'
} else if (window.location.hostname.indexOf('test') !== -1 || window.location.hostname.indexOf('localhost') !== -1) {
  baseUrl = 'https://test-plat.cnabke.com/'
} else {
  baseUrl = 'https://plat.cnabke.com/'
}
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.code !== 3007001) {
      message.error({
        message: res.message || 'Error',
        duration: 5,
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   Msgbox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  (error) => {
    console.log('err' + error); // for debug
    message.error({
      content: error.message,
      duration: 5,
    });
    return Promise.reject(error);
  }
);

export default service;
