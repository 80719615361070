import Vue from "vue";
import router from "./router";
// import router from './router/school-content';
import App from "./App.vue";
import VueMeta from "vue-meta";
import VueLazyload from "vue-lazyload";
// 引入阿里云字体图标css
import "@/assets/font/iconfont.css";
import "@/assets/font/iconfont";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(VueLazyload, {
  preLoad: 1.3, //预载高度比例
  error: require("./assets/images/error.png"), //加载失败时图像的 src
  loading: require("./assets/images/loading.png"), //加载时图像的 src
  attempt: 1, //	尝试次数
  listenEvents: ["scroll"], //	你想让 vue 监听的事件
});

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
import Antd from "ant-design-vue";

import "ant-design-vue/dist/antd.css";
Vue.config.productionTip = false;

Vue.use(Antd);

// import '@/assets/font/font.css';
import "../common/font/font.css";
import "../common/css/common.css";
import "../common/css/media.css";
// import {
//   Layout,
//   Button,
//   Menu,
//   Carousel,
//   Tabs,
//   Row,
//   Col,
//   List,
//   Affix,
//   BackTop,
//   Popover,
//   Form,
//   Select,
//   Input,
//   Modal,
//   Spin,
//   PageHeader,
//   Radio,
//   Anchor
// } from 'ant-design-vue';
// Vue.use(Layout);
// Vue.use(Button);
// Vue.use(Menu);
// Vue.use(Carousel);
// Vue.use(Tabs);
// Vue.use(Row);
// Vue.use(Col);
// Vue.use(List);
// Vue.use(Affix);
// Vue.use(BackTop);
// Vue.use(Popover);
// Vue.use(Form);
// Vue.use(Select);
// Vue.use(Input);
// Vue.use(Modal);
// Vue.use(Spin);
// Vue.use(PageHeader);
// Vue.use(Anchor);
// Vue.use(Radio);

import "./rem.js";
// Vue.use(Antd);

new Vue({
  // el: '#app',
  router,
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
