<template>
  <div id="app" ref="vueApp">
    <input id="kfStatus" type="hidden" value="0">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>
<script>
  import { getLoginUser } from '@/api/app-crm.js'
//   import $ from "jquery";
   export default {
     name: 'App',
     data() {
       return {

       }
     },
     mounted() {
//       getLoginUser({token: this.getCookie('token')}).then(res => {
//
//       })
     },
     methods: {
       getCookie(name) {
         var arr = document.cookie.match(
           new RegExp("(^| )" + name + "=([^;]*)(;|$)")
         );
         if (arr != null) return unescape(arr[2]);
         return null;
       }
     }
   }

//
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: AlibabaPuHuiTiM, Alibaba-PuHuiTi, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}
/* div{
  box-sizing: border-box;
} */
</style>
