// 基准大小
const baseSize = 37.5;
// 设置 rem 函数
function setRemPc() {
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
  console.log(document.documentElement.clientWidth, '--------------------');
  let screenWidth = document.documentElement.clientWidth;
  if (screenWidth >= 1920) screenWidth = 1920;
  const scale = screenWidth / 1920;
  // 设置页面根节点字体大小, 字体大小最小为12;
  // let fontSize =
  //   baseSize * Math.min(scale, 2) > 12 ? baseSize * Math.min(scale, 2) : 12;
  // document.documentElement.style.fontSize = fontSize + 'px';

  const size = scale * baseSize;
  document.documentElement.style.fontSize = size + 'px';
}

function setRem() {
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
  console.log(
    '移动端',
    document.documentElement.clientWidth,
    '--------------------'
  );
  let screenWidth = document.documentElement.clientWidth;

  // const baseSize = 37.5;
  const scale = screenWidth / 750;

  const size = scale * 17.3;
  document.documentElement.style.fontSize = size + 'px';
}

if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  setRem();
  window.addEventListener('resize', setRem); //浏览器窗口大小改变时调用rem换算方法
  // 初始化
  setRem();
  // 改变窗口大小时重新设置 rem,这里最好加上节流
  window.onresize = function() {
    setRem();
  };
} else {
  //初始化
  setRemPc();
  //改变窗口大小时重新设置 rem,这里最好加上节流
  window.onresize = function() {
    setRemPc();
  };
  // setRemPC()
  // window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法
}
